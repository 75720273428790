import { extendTheme } from '@chakra-ui/react'
import bg from '../assets/bg.svg'

// Components styles
import Text from './components/Text'
import Heading from './components/Heading'
import Button from './components/Button'

const theme = extendTheme({
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  colors: {
    brand: {
      500: '#1B2431',
    },
  },
  fonts: {
    heading: `'Montserrat', sans-serif`,
    body: `'Montserrat', sans-serif`,
  },
  styles: {
    global: {
      'html, body': {
        backgroundColor: 'brand.500',
        backgroundImage: bg,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      },
    },
  },
  components: {
    Text,
    Heading,
    Button,
  },
})

export default theme
